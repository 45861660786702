import React from 'react';
import { styled, Box, Button } from '@material-ui/core';
import { TableContainer, Table, TableBody, TableRow, TableCell } from '@material-ui/core';
import { Card, CardHeader, CardContent } from '@material-ui/core';
import { ScrollRestoreContainer } from 'components/ui/layout';

import RegistrationReview from 'components/registration/RegistrationReview';


export default function OrderReview(props) {
    const { registration, loading, onCancel, onSubmit } = props;
    const { firstName, lastName, email, phone, company, address, city, province, postalCode, country } = props.customer;

    const products = props.cart.products.filter(t => t.quantity > 0);
    const bundles = props.cart.bundles.filter(t => t.quantity > 0);


    return (
        <ScrollRestoreContainer>

            <FormContainer>

                <Box mb={4}>
                    <Card>
                        <CardHeader title='Customer' />

                        <CardContent>

                            <TableContainer component={Box}>
                                <Table size='small'>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell>Name</TableCell>
                                            <TableCell align='right'>{firstName} {lastName}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Email</TableCell>
                                            <TableCell align='right'>{email}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Phone</TableCell>
                                            <TableCell align='right'>{phone.trim() || '-'}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Company Name</TableCell>
                                            <TableCell align='right'>{company.trim() || '-'}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Address</TableCell>
                                            <TableCell align='right'>{address.trim() || '-'}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>City</TableCell>
                                            <TableCell align='right'>{city.trim() || '-'}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Province</TableCell>
                                            <TableCell align='right'>{province || '-'}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Postal Code</TableCell>
                                            <TableCell align='right'>{postalCode.trim() || '-'}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Country</TableCell>
                                            <TableCell align='right'>{country === 'CA' ? 'Canada' : 'United States'}</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>

                        </CardContent>

                    </Card>
                </Box>


                <Box mb={4}>
                    <Card>
                        <CardHeader title='Tickets' />

                        <CardContent>

                            <TableContainer component={Box}>
                                <Table size='small'>
                                    <TableBody>
                                        {bundles.map(t => (
                                            <TableRow key={t.id}>
                                                <TableCell>{t.name}</TableCell>
                                                <TableCell align='right'>{t.quantity}</TableCell>
                                            </TableRow>
                                        ))}
                                        {products.map(t => (
                                            <TableRow key={t.id}>
                                                <TableCell>{t.name}</TableCell>
                                                <TableCell align='right'>{t.quantity}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>

                        </CardContent>

                    </Card>
                </Box>

                {registration.length > 0 && (
                    <Box mb={4}>
                        <RegistrationReview attendees={registration} />
                    </Box>
                )}

                <ButtonContainer>
                    <BackButton variant='outlined' disabled={loading} onClick={onCancel}>Back</BackButton>
                    <CreateButton variant='contained' disabled={loading} onClick={onSubmit}>Create</CreateButton>
                </ButtonContainer>
        
            </FormContainer>

        </ScrollRestoreContainer>
    )
}



const FormContainer = styled(Box)(({ theme }) => ({
    width: theme.breakpoints.values.md,
    paddingBottom: theme.spacing(10)
}));

const ButtonContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
}));

const BackButton = styled(Button)(({ theme }) => ({
    color: theme.palette.warning.main,
    borderColor: theme.palette.warning.main,
    marginLeft: theme.spacing(2),
}));
BackButton.defaultProps = { variant: 'outlined' }

const CreateButton = styled(Button)(({ theme }) => ({
    backgroundColor: theme.palette.success.main,
    color: theme.palette.common.white,
    marginLeft: theme.spacing(2),
}));
CreateButton.defaultProps = { variant: 'contained' }
import React, { useState } from 'react';

// Material UI
import { styled } from '@material-ui/core/styles';
import { Paper, CircularProgress, TextField, IconButton, Tabs, Tab, Box, Typography } from '@material-ui/core';
import { DataGrid } from '@mui/x-data-grid';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import NotInterestedIcon from '@material-ui/icons/NotInterested';

// Utility
import useExpresso from 'hooks/useExpresso';
import { normalizeCharacters } from 'utility/strings';


export default function UserList(props) {
    // Props
    const { dependency, onUserSelect } = props;

    const [category, setCategory] = useState('all');
    const [searchValue, setSearchValue] = useState('');

    // Fetch users by event ID
    const [users, loading, error]  = useExpresso(`/apiv2/users/`, 'GET', null, null, [dependency]);


    // RETURN: Loading
    if (loading) {
        return (
            <Box display='flex' justifyContent='center' alignItems='center' height={72}>
                <CircularProgress />
            </Box>
        )
    }
    
    // RETURN: Error
    if (error || !users) {
        return (
            <Box display='flex' justifyContent='center' alignItems='center' height={72}>
                <Typography variant='subtitle2'>There was a problem loading users</Typography>
            </Box>
        )
    };

    // RETURN: No users
    if (users.length === 0) {
        return (
            <Box display='flex' justifyContent='center' alignItems='center' height={72}>
                <Typography variant='subtitle2'>No users found</Typography>
            </Box>
        )
    }


    let filteredUsers = [];


    // ***** Filter Users by Category ***** //

    const admin    = users.filter(u => u.super_admin === 1);
    const enabled  = users.filter(u => u.status === 1);
    const disabled = users.filter(u => u.status === 0);

    if (category === 'all')      filteredUsers = users;
    if (category === 'admin')    filteredUsers = admin;
    if (category === 'enabled')  filteredUsers = enabled;
    if (category === 'disabled') filteredUsers = disabled;


    // ***** Filter Users by Search Query ***** //

        if (searchValue.trim()) {
        const searchStr = normalizeCharacters(searchValue.trim()); // Normalize string to remove accents and diacritics

        const searchRegex = new RegExp(searchStr.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&'), 'i');
        
        filteredUsers = filteredUsers.filter((row) => {
            const name = normalizeCharacters(row.name);
            const email = normalizeCharacters(row.email);
            const host = normalizeCharacters(row.host_name || '');
            return searchRegex.test(name) || searchRegex.test(email) || searchRegex.test(host);
        });
    }


    // ***** DataGrid Rows and Columns ***** //

    const rows = filteredUsers.map(u => ({
        id: u.user_id,
        c1: { admin: u.super_admin, status: u.status },
        c2: u.name,
        c3: u.email,
        c4: u.host_name || '-',
    }));

    const columns = [
        { field: 'c1', headerName: ' ',     width: 50,  hideSortIcons: true, renderCell: ({ value }) => <UserStatusIcons {...value} />},
        { field: 'c2', headerName: 'NAME',  width: 320, hideSortIcons: true, },
        { field: 'c3', headerName: 'EMAIL', width: 320, hideSortIcons: true, },
        { field: 'c4', headerName: 'HOST',  width: 520, hideSortIcons: true, },
    ]


    return (
        <TableContainer>
            <Tabs value={category} onChange={(_, value) => setCategory(value)}>
                <Tab value='all'      label={`All (${users.length})`} />
                <Tab value='admin'    label={`Admin (${admin.length})`} />
                <Tab value='enabled'  label={`Active (${enabled.length})`} />
                <Tab value='disabled' label={`Inactive (${disabled.length})`} />
            </Tabs>
            <DataGrid
                onRowClick={(params) => onUserSelect(params.id)}
                disableSelectionOnClick
                components={{ Toolbar: Toolbar }}
                componentsProps={{
                    toolbar: {
                        value: searchValue,
                        onChange: (event) => setSearchValue(event.target.value),
                        clearSearch: () => setSearchValue(''),
                    },
                }}
                rows={rows}
                columns={columns}
                disableColumnMenu
                sortingOrder={['desc', 'asc']}
            />
        </TableContainer>
    )
}


function Toolbar(props) {
    const { value, onChange, clearSearch } = props;

    return (
        <SearchBar
            autoFocus
            variant="outlined"
            size='small'
            value={value}
            onChange={onChange}
            placeholder="Search..."
            InputProps={{
            startAdornment: <SearchIcon fontSize="small" />,
            endAdornment: (
                <IconButton
                    title="Clear"
                    aria-label="Clear"
                    size="small"
                    style={{ visibility: value ? 'visible' : 'hidden' }}
                    onClick={clearSearch}
                >
                    <ClearIcon fontSize="small" />
                </IconButton>
            )}}
        />
    )
}

function UserStatusIcons(props) {
    const { admin, status } = props;

    if (status === 0) {
        return (
            <DisabledIcon />
        )
    }

    if (admin === 1) {
        return (
            <AdminIcon />
        )
    }

    return null;
}


const TableContainer = styled(Paper)(({ theme }) => ({
    height: '100%'
}));

const SearchBar = styled(TextField)(({ theme }) => ({
    margin: theme.spacing(2),
    marginBottom: theme.spacing(1),
    width: '360px'
}));

const AdminIcon = styled(SupervisorAccountIcon)(({ theme }) => ({
    color: theme.palette.success.main
}));

const DisabledIcon = styled(NotInterestedIcon)(({ theme }) => ({
    color: theme.palette.grey[600]
}));
import React from 'react';
import { styled, Box, TextField, Typography, FormGroup, FormControlLabel, Checkbox, FormControl, FormHelperText, RadioGroup, Radio, Select } from '@material-ui/core';
import { ToggleButton as _ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { ThemeProvider } from '@material-ui/core/styles';
import { datePickerTheme } from 'context/theme';
import { alpha } from '@material-ui/core/styles/colorManipulator';


export default function RegistrationField(props) {
    switch (props.type) {
        case 1:
            return <ShortAnswer {...props} />
        case 2:
            return <Paragraph {...props} />
        case 3:
            return <SingleChoice {...props} />
        case 4:
            return <MultipleChoice {...props} />
        case 5:
            return <Dropdown {...props} />
        case 6:
            return <FullDate {...props} />
        case 7:
            return <Age {...props} />
        case 8:
            return <YesOrNo {...props} />
        case 9:
            return <ShortDate {...props} />
        case 10:
            return <TermsAndConditions {...props} />
        case 999:
            return <TextBlock {...props} />
        case 998:
            return <ImageBlock {...props} />
        default:
            return null;
    }
}


function ShortAnswer(props) {
    return (
        <Question>
            <QuestionHeader
                required={false}
                title={props.desc}
            />
            <QuestionContent>
                <TextField
                    value={props.value}
                    variant='outlined'
                    fullWidth
                    error={Boolean(props.errorMsg)}
                    helperText={props.errorMsg}
                    onChange={e => props.onChange(props.index, e.target.value)}
                />
            </QuestionContent>
        </Question>
    )
}

function Paragraph(props) {
    return (
        <Question>
            <QuestionHeader
                required={false}
                title={props.desc}
            />
            <QuestionContent>
                <TextField
                    value={props.value}
                    variant='outlined'
                    fullWidth
                    multiline
                    minRows={6}
                    maxRows={6}
                    error={Boolean(props.errorMsg)}
                    helperText={props.errorMsg}
                    onChange={e => props.onChange(props.index, e.target.value)}
                />
            </QuestionContent>
        </Question>
    )
}

function SingleChoice(props) {
    return (
        <Question>
            <QuestionHeader
                required={false}
                title={props.desc}
                subheader={'Pick one'}
            />
            <QuestionContent>
                <FormControl error={Boolean(props.errorMsg)}>
                    <RadioGroup aria-label={'single choice'} name={'single choice'} value={props.value} onChange={(e) => props.onChange(props.index, Number(e.target.value))}>
                        {props.options.map(o => (
                            <FormControlLabel
                                key={o.id}
                                value={o.id}
                                control={<Radio />}
                                label={o.desc}
                            />
                        ))}
                    </RadioGroup>
                    <FormHelperText>{props.errorMsg}</FormHelperText>
                </FormControl>
            </QuestionContent>
        </Question>
    )
}

function MultipleChoice(props) {
    return (
        <Question>
            <QuestionHeader
                required={false}
                title={props.desc}
                subheader={'Select all that apply'}
            />
            <QuestionContent>
                <FormControl error={Boolean(props.errorMsg)}>
                    <FormGroup>
                        {props.options.map(o => (
                            <FormControlLabel
                                key={o.id}
                                control={
                                    <Checkbox
                                        checked={props.value.has(o.id)}
                                        onChange={(e) => props.onChange(props.index, e.target.checked, o.id)} name={o.desc}
                                    />
                                }
                                label={o.desc}
                            />
                        ))}
                    </FormGroup>
                    <FormHelperText>{props.errorMsg}</FormHelperText>
                </FormControl>
            </QuestionContent>
        </Question>
    )
}

function Dropdown(props) {
    return (
        <Question>
            <QuestionHeader
                required={false}
                title={props.desc}
                subheader={'Pick one'}
            />
            <QuestionContent>
                <FormControl variant='outlined' error={Boolean(props.errorMsg)}>
                    <Select
                        native
                        value={props.value}
                        onChange={(e) => props.onChange(props.index, Number(e.target.value))}
                    >
                            <option disabled value=''></option>
                            {props.options.map(o => (
                                <option key={o.id} value={o.id}>{o.desc}</option>
                            ))}
                    </Select>
                    <FormHelperText>{props.errorMsg}</FormHelperText>
                </FormControl>
            </QuestionContent>
        </Question>
    )
}

function FullDate(props) {
    return (
        <Question>
            <QuestionHeader
                required={false}
                title={props.desc}
            />
            <QuestionContent>
                <ThemeProvider theme={datePickerTheme}>
                    <KeyboardDatePicker
                        value={props.value}
                        error={Boolean(props.errorMsg)}
                        helperText={props.errorMsg || undefined}
                        color='secondary'
                        placeholder='YYYY-MM-DD'
                        format='yyyy-MM-dd' // date-fns doesn't like YYYY-MM-DD. We can switch back when we replace date-fns with day.js (or moment)
                        inputVariant='outlined'
                        onChange={(d) => props.onChange(props.index, d)}
                    />
                </ThemeProvider>
            </QuestionContent>
        </Question>
    )
}

function ShortDate(props) {
    return (
        <Question>
            <QuestionHeader
                required={false}
                title={props.desc}
            />
            <QuestionContent>
                <ThemeProvider theme={datePickerTheme}>
                    <KeyboardDatePicker
                        value={props.value}
                        error={Boolean(props.errorMsg)}
                        helperText={props.errorMsg || undefined}
                        color='secondary'
                        placeholder='YYYY-MM'
                        format='yyyy-MM'
                        views={['year', 'month']}
                        inputVariant='outlined'
                        onChange={(d) => props.onChange(props.index, d)}
                    />
                </ThemeProvider>
            </QuestionContent>
        </Question>
    )
}

function Age(props) {
    return (
        <Question>
            <QuestionHeader
                required={false}
                title={props.desc}
            />
            <QuestionContent>
                <TextField
                    value={props.value}
                    variant='outlined'
                    color='secondary'
                    type='tel'
                    error={Boolean(props.errorMsg)}
                    helperText={props.errorMsg ?? undefined}
                    onChange={(e) => props.onChange(props.index, e.target.value)}
                />
            </QuestionContent>
        </Question>
    )
}

function YesOrNo(props) {

    const handleChange = (e, value) => {
        if (value === 'true') props.onChange(props.index, true)
        if (value === 'false') props.onChange(props.index, false)
    };

    return (
        <Question>
            <QuestionHeader
                required={false}
                title={props.desc}
            />
            <QuestionContent>
                <FormControl error={Boolean(props.errorMsg)}>
                    <ToggleButtonGroup
                        value={String(props.value)}
                        size='small'
                        exclusive
                        onChange={handleChange}
                        aria-label={'yes or no'}
                    >
                        <ToggleButton value="true" aria-label={'yes'}>
                            <Typography>Yes</Typography>
                        </ToggleButton>
                        <ToggleButton value="false" aria-label={'no'}>
                            <Typography>No</Typography>
                        </ToggleButton>
                    </ToggleButtonGroup>
                    <FormHelperText>{props.errorMsg}</FormHelperText>
                </FormControl>
            </QuestionContent>
        </Question>
    )
}


function TermsAndConditions(props) {
    return (
        <Question>
            <QuestionHeader
                required={false}
                title={props.desc}
            />
            <QuestionContent>
                <FormControl error={Boolean(props.errorMsg)}>
                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={props.value}
                                    onChange={(e) => props.onChange(props.index, e.target.checked)}
                                />
                            }
                            label={'I agree'}
                        />
                    </FormGroup>
                    <FormHelperText>{props.errorMsg}</FormHelperText>
                </FormControl>
            </QuestionContent>
        </Question>
    )
}

function TextBlock(props) {
    // Extra top margin if the text block is not first on the form
    // Helpful for grouping form into sections
    return (
        <Box mt={props.index === 0 ? 5 : 8} mb={5}>
            <BlockContent variant='body1'>{props.desc}</BlockContent>
        </Box>
    )
}


function ImageBlock(props) {
    return (
        <ImageContainer>
            {props.imageLink ? (
                <ImageLink href={props.imageLink} target="_blank">
                    <Image src={props.imageUrl} alt='' />
                </ImageLink>
            ) : (
                <Image src={props.imageUrl} alt='' />
            )}
        </ImageContainer>
    )
}



function QuestionHeader(props) {
    return (
        <Box>
            <Box display={'flex'}>
                <Title>{props.title}</Title>
                {props.required && <Required />}
            </Box>
            {props.subheader && (
                <Subheader variant='caption' display='block'>{props.subheader}</Subheader>
            )}
        </Box>
    )
}


const Question = styled(Box)(({ theme }) => ({
    marginBottom: theme.spacing(4),
}));

const QuestionContent = styled(Box)(({ theme }) => ({
    marginTop: theme.spacing(2),
}));

const BlockContent = styled(Typography)(({ theme }) => ({
    fontWeight: 'bold',
    whiteSpace: 'pre-wrap'
}));

const Title = styled(Typography)(({ theme }) => ({
    whiteSpace: 'pre-wrap',
}));
Title.defaultProps = { display: 'inline' };

const Required = styled(Typography)(({ theme }) => ({
    fontWeight: 'bold',
    color: theme.palette.error.dark,
    marginLeft: theme.spacing(1)
}));
Required.defaultProps = { variant: 'h6', display: 'inline', children: '*' };

const Subheader = styled(Typography)(({ theme }) => ({
    display: 'block'
}));
Subheader.defaultProps = { variant: 'caption' };

const ToggleButton = styled(_ToggleButton)(({ theme }) => ({
    width: 100,
    borderColor: theme.palette.grey[700],
    color: theme.palette.grey[700],
    '&.Mui-selected': {
        borderColor: theme.palette.info.dark,
        color: theme.palette.info.dark,
        backgroundColor: alpha(theme.palette.info.light, 0.2)
    }
}));

const ImageContainer = styled('div')(({ theme }) => ({
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '520px',
    borderRadius: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
        width: '100%'
    }
}));

const ImageLink = styled('a')(({ theme }) => ({
    width: '100%'
}));

const Image = styled('img')(({ theme }) => ({
    width: '100%',
    objectFit: 'contain',
    borderRadius: theme.spacing(2)
}));
import React, { useState, useEffect } from 'react';

// Material UI
import { styled, Paper, CircularProgress, IconButton, Link, Box, Typography, TextField } from '@material-ui/core';
import { Tabs, Tab } from '@material-ui/core';
import { Menu, MenuItem } from '@material-ui/core';
import { DataGrid } from '@mui/x-data-grid';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';

// Utility
import { abbreviateNumber, formatPrice } from '../utility/numbers';
import { StripeConnectStatus } from './ui/icons';
import fetchExpresso from 'utility/fetchExpresso';
import { useLocation } from 'react-router-dom';
import { normalizeCharacters } from 'utility/strings';


export default function HostList(props) {
    // Props
    const { dependency, onEditHost, onViewPricing, onViewConnectDetails } = props;

    // State
    const [state, setState] = useState({
        hosts: [],
        loading: true,
        error: false
    });

    const location = useLocation();

    // Search and Filter
    const [category, setCategory] = useState(null);
    const [searchValue, setSearchValue] = useState('');

    // Menu Button 
    const [anchorEl, setAnchorEl] = useState(null);
    const [host, setHost] = useState({ id: null, name: null, connectId: null }); // Currently selected host (via overflow menu)
  

    // Fetch hosts by event ID
    useEffect(() => {
        fetchExpresso('/apiv2/hosts/')
            .then(res => {
                if (res.status !== 200) {
                    throw new Error();
                }
                return res.json();
            })
            .then(data => {
                setState(s => ({
                    ...s,
                    hosts: data,
                    loading: false,
                    error: false
                }))
            })
            .catch(e => {
                setState(s => ({ ...s, hosts: [], loading: false, error: true }));
            });
    }, [dependency]);


    // ===== Check url query params for the default AdminEventList tab to open first ===== //

    useEffect(() => {
        if (location.search.startsWith('?tab=')) {
            const tab = location.search.slice(5);
            if (tab === 'warning') {
                setCategory('attention');
            } else {
                setCategory('all');
            }
        } else {
            setCategory('all');
        }
    }, [location.search]);

    if (category === null) return null;


    // RETURN: Loading
    if (state.loading) {
        return (
            <Box display='flex' justifyContent='center' alignItems='center' height={72}>
                <CircularProgress />
            </Box>
        )
    }
    
    // RETURN: Error
    if (state.error || !state.hosts) {
        return (
            <Box display='flex' justifyContent='center' alignItems='center' height={72}>
                <Typography variant='subtitle2'>There was a problem loading hosts</Typography>
            </Box>
        )
    };

    // RETURN: No hosts
    if (state.hosts.length === 0) {
        return (
            <Box display='flex' justifyContent='center' alignItems='center' height={72}>
                <Typography variant='subtitle2'>No hosts found</Typography>
            </Box>
        )
    }


    let filteredHosts = []; // Hosts to display based on category and search filters applied
    

    // ***** Filter by Connect Account Status ***** //

    let connected = []; // No issues w/connect account
    let attention = []; // Connect account needs attention
    let onboarding = []; // Connect account is not onboarded yet
    let legacy = []; // Host is not using Connect yet
    

    for (const h of state.hosts) {
        if (!h.connect_id) {
            legacy.push(h);
        }
        else if (!h.onboarding_complete) {
            onboarding.push(h);
        }
        else if (h.requirements_due || !h.charges_enabled || !h.payouts_enabled) {
            attention.push(h);
        }
        else {
            connected.push(h);
        }
    }

    if (category === 'all')        filteredHosts = state.hosts;
    if (category === 'connected')  filteredHosts = connected;
    if (category === 'attention')  filteredHosts = attention;
    if (category === 'onboarding') filteredHosts = onboarding;
    if (category === 'legacy')     filteredHosts = legacy;


    // ***** Filter by Search Query ***** //

    if (searchValue.trim()) {
        const searchStr = normalizeCharacters(searchValue.trim()); // Normalize string to remove accents and diacritics

        const searchRegex = new RegExp(searchStr.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&'), 'i');
        
        filteredHosts = filteredHosts.filter((row) => {
            const hostName = normalizeCharacters(row.host_name);
            return searchRegex.test(hostName);
        });
    }


    const rows = filteredHosts.map(h => ({
        id: h.host_id,
        c1: null, // This field just shows the index of the row
        c2: h.host_name,
        c3: h.upcoming_events + '/' + h.events,
        c4: h.orders,
        c5: h.tickets,
        c6: h.sales,
        c7: h.fees,
        c8: h.avg_ticket_price,
        c9: h.avg_fee_percent_per_order,
        c10: h.avg_fee_per_ticket,
        c11: h.avg_tickets_per_order,
        c12: {
            connect_id: h.connect_id,
            charges_enabled: h.charges_enabled,
            payouts_enabled: h.payouts_enabled,
            onboarding_complete: h.onboarding_complete,
            requirements_due: h.requirements_due,
        },
        c13: h.host_id,
        // Other data
        connectId: h.connect_id
    }));

    const columns = [
        // Note: Generating the first field (rank) dynamically causes performance issues. Lets monitor this as the list grows.
        { field: 'c1',  headerName: ' ',       width: 50,  hideSortIcons: true,
            renderCell: (params) => params.api.getRowIndex(params.id) + 1 },
        { field: 'c2',  headerName: 'NAME',    flex: 1, minWidth: 300, hideSortIcons: true,
            renderCell: (params) => (
                <HostName onClick={e => handleMenuOpen(e, params.id)}>
                    <Typography variant='subtitle2'>{params.value}</Typography>
                </HostName>
            )},
        { field: 'c3',  headerName: 'EV',      width: 80,  hideSortIcons: true, },
        { field: 'c4',  headerName: 'ORD',     width: 80,  hideSortIcons: true, type: 'number' },
        { field: 'c5',  headerName: 'TIX',     width: 80,  hideSortIcons: true, type: 'number' },
        { field: 'c6',  headerName: 'PRC',     width: 80,  hideSortIcons: true, type: 'number', valueFormatter: params => '$' + abbreviateNumber(params.value, 0, 1) },
        { field: 'c7',  headerName: 'FD',      width: 80,  hideSortIcons: true, type: 'number', valueFormatter: params => '$' + abbreviateNumber(params.value, 0, 1) },
        { field: 'c8',  headerName: 'AVG TKT', width: 100, hideSortIcons: true, type: 'number', valueFormatter: params => formatPrice(params.value) },
        { field: 'c9',  headerName: 'FD%',     width: 80,  hideSortIcons: true, type: 'number', valueFormatter: params => params.value.toFixed(1) + '%' },
        { field: 'c10', headerName: 'FD/TKT',  width: 100, hideSortIcons: true, type: 'number', valueFormatter: params => formatPrice(params.value) },
        { field: 'c11', headerName: 'TKT/ORD', width: 100, hideSortIcons: true, type: 'number', },
        { field: 'c12', headerName: 'STR',     width: 80,  hideSortIcons: true, align: 'center',
          renderCell: params => (
            <StripeConnectStatus
                connect_id={params.value.connect_id}
                charges_enabled={params.value.charges_enabled}
                payouts_enabled={params.value.payouts_enabled}
                onboarding_complete={params.value.onboarding_complete}
                requirements_due={params.value.requirements_due}
            />
        )},
        { field: 'c13', headerName: ' ',       width: 80,  hideSortIcons: true, align: 'center',
          renderCell: params => (
            <IconButton onClick={e => handleMenuOpen(e, params.value)}>
                <MoreVertIcon />
            </IconButton>
        )},
    ]


    const handleMenuOpen = (event, hostId) => {
        const h = rows.find(r => r.id === hostId);
        setHost({ id: hostId, name: h.c2, connectId: h.connectId });
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setHost({ id: null, name: null });
        setAnchorEl(null);
    };

    const handleEdit = () => {
        onEditHost(host.id);
        handleMenuClose();
    };

    const handlePricing = () => {
        onViewPricing(host.id, host.name);
    };

    const handleConnectDetails = () => {
        onViewConnectDetails(host.id, host.name);
    };


    let connectAccountLink;
    
    if (host.connectId) {
        connectAccountLink = process.env.REACT_APP_ENV === 'production'
            ? `https://dashboard.stripe.com/connect/accounts/${host.connectId}`
            : `https://dashboard.stripe.com/test/connect/accounts/${host.connectId}`;
    }


    // RETURN: ProductList
    return (
        <>
            <TableContainer>
                <Tabs value={category} onChange={(_, value) => setCategory(value)}>
                    <Tab value='all' label={`All (${state.hosts.length})`} />
                    <Tab value='connected' label={`Connected (${connected.length})`} />
                    <Tab value='attention' label={`Attention (${attention.length})`} />
                    <Tab value='onboarding' label={`Onboarding (${onboarding.length})`} />
                    <Tab value='legacy' label={`Legacy (${legacy.length})`} />
                </Tabs>
                <DataGrid
                    components={{ Toolbar: Toolbar }}
                    componentsProps={{
                        toolbar: {
                            value: searchValue,
                            onChange: (event) => setSearchValue(event.target.value),
                            clearSearch: () => setSearchValue(''),
                        },
                    }}
                    rows={rows}
                    columns={columns}
                    disableColumnMenu
                    sortingOrder={['desc', 'asc']}
                />
            </TableContainer>

            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
            >
                <MenuItem onClick={handleEdit}>Edit Host</MenuItem>
                <MenuItem onClick={handlePricing}>Host Pricing</MenuItem>
                {host.connectId ? (
                    <MenuItem onClick={handleConnectDetails}>Connect Details</MenuItem>
                ) : null}
                {host.connectId ? (
                    <LinkContainer href={connectAccountLink}>
                        <MenuItem>View Connect Account (Stripe)</MenuItem>
                    </LinkContainer>
                ) : null}
            </Menu>
        </>
    )
}


function Toolbar(props) {
    const { value, onChange, clearSearch } = props;

    return (
        <SearchBar
            autoFocus
            variant="outlined"
            size='small'
            value={value}
            onChange={onChange}
            placeholder="Search..."
            InputProps={{
            startAdornment: <SearchIcon fontSize="small" />,
            endAdornment: (
                <IconButton
                    title="Clear"
                    aria-label="Clear"
                    size="small"
                    style={{ visibility: value ? 'visible' : 'hidden' }}
                    onClick={clearSearch}
                >
                    <ClearIcon fontSize="small" />
                </IconButton>
            )}}
        />
    )
}


const TableContainer = styled(Paper)(({ theme }) => ({
    height: '100%'
}));

const HostName = styled(Box)(({ theme }) => ({
    '&:hover': {
        cursor: 'pointer',
    }
}));

const LinkContainer = styled(Link)(({ theme }) => ({}));

LinkContainer.defaultProps = {
    color: 'inherit',
    underline: 'none',
    target: '_blank',
    rel: 'noopener noreferrer'
}

const SearchBar = styled(TextField)(({ theme }) => ({
    margin: theme.spacing(2),
    marginBottom: theme.spacing(1),
    width: '360px'
}));
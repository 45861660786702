import React, { useState } from 'react';

// Material UI
import { styled, useTheme } from '@material-ui/core/styles';
import { Box, Grid, MenuItem, Paper, Card, CardContent, CardActions, Typography, FormControlLabel, Switch } from '@material-ui/core';
import { CircularProgress } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import FileCopyIcon from '@material-ui/icons/FileCopy';

// Forms
import { useTextInput, useToggle } from 'hooks/forms';
import { TextField } from './ui/inputs';

// Utility
import { useNotification } from 'context/notification';
import fetchExpresso from 'utility/fetchExpresso';
import useExpresso from 'hooks/useExpresso';


export default function UserFormContainer(props) {
    if (props.userId) {
        return <UserFormEditMode {...props} editMode={true} />
    }
    else {
        return <UserForm {...props} editMode={false} />
    }
}

// Wrapper that sets default form values when editing an existing user
function UserFormEditMode(props) {
    const [user, loading, error]  = useExpresso(`/apiv2/users/${props.userId}`, 'GET', null, null, []);

    if (loading) {
        return (
            <Box display='flex' flex={1} flexDirection='column' justifyContent='center' alignItems='center'>
                <CircularProgress />
            </Box>
        )
    }
    
    if (error) {
        return (
            <Box display='flex' flex={1} flexDirection='column' justifyContent='center' alignItems='center'>
                <p>Error loading user</p>
            </Box>
        )
    }

    if (user) {
        return <UserForm {...props} name={user.name} email={user.email} hostId={user.host_id} status={user.status} />
    }
}


function UserForm(props) {
    // Props
    const { editMode, onUpdate, onCancel, userId } = props;

    const [hosts]  = useExpresso(`/api/auths/host_select`, 'GET', null, null);

    // State
    const [loading, setLoading] = useState(false);

    // Form Values
    const password1 = useTextInput({ disabled: editMode });
    const password2 = useTextInput({ disabled: editMode });
    const name = useTextInput({ defaultValue: props.name || '' });
    const email = useTextInput({ defaultValue: props.email || '', noSpaces: true });
    const hostId = useTextInput({ defaultValue: props.hostId || '' });
    const status = useToggle({ defaultValue: props.status === 1 });


    // Hooks
    const { notify } = useNotification();
    const theme = useTheme();

    function handleSubmit() {
        if (loading) return null;

        // Error Handling
        let formHasError = false;


        if (editMode === false) { // only validate passwords when creating new user
            if (password1.value.length === 0) {
                password1.setError('This field cannot be empty');
                formHasError = true;
            };
    
            if (password1.value !== password2.value || password2.value.length === 0) {
                password2.setError('Your passwords do not match');
                formHasError = true;
            };
        }

        if (name.value.trim().length === 0) {
            name.setError('This field cannot be empty');
            formHasError = true;
        };

        if (/.+@.+\..+/.test(email.value.trim()) === false) {
            email.setError('This field cannot be empty');
            formHasError = true;
        };

        if (hostId.value === '') {
            hostId.setError('This field cannot be empty');
            formHasError = true;
        };

        if (formHasError) {
            return null;
        }

        setLoading(true);

        const endpoint = editMode
            ? `/apiv2/users/${userId}`
            : '/apiv2/users/'

        fetchExpresso(endpoint, {
            method: editMode ? 'PUT' : 'POST',
            body: {
                password: password1.value, // ignored by api when editing user
                name: name.value,
                email: email.value,
                hostId: hostId.value,
                status: status.checked ? 1 : 0
            }
        })
            .then(res => {
                if (res.status === 200) {
                    editMode
                        ? notify.success('Updated user')
                        : notify.success('Created new user');
                    onUpdate();
                }
                
                else if (res.status === 409) {
                    notify.warning('Email already in use');
                    setLoading(false);
                }
                
                else {
                    throw new Error();
                }
            })
            .catch(e => {
                editMode
                    ? notify.error('There was a problem updating your user')
                    : notify.error('There was a problem creating your user');
                setLoading(false);
            })
    }

    const copyIdToClipboard = async () => {
        try {
            await navigator.clipboard.writeText(userId);
            notify.info('Copied to clipboard!')
        } catch(e) {
            notify.error('Unable to copy to clipboard')
        }
    }

    const hostsLoaded = (hosts && hosts.length > 0);

    return (
        <Box display='flex' justifyContent='center' width='100%'>
            <Box display='flex' flexDirection='column' flexGrow={1} p={[2, 4]} maxWidth={theme.breakpoints.values.sm}>

                <FormContainer>

                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                {...name.formProps}
                                required
                                label='Name'
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                {...email.formProps}
                                required
                                label='Email Address'
                            />
                        </Grid>
                        {editMode === false && (
                            <Grid item xs={12}>
                                <TextField
                                    {...password1.formProps}
                                    required
                                    label='Password'
                                />
                            </Grid>
                        )}
                        {editMode === false && (
                            <Grid item xs={12}>
                                <TextField
                                    {...password2.formProps}
                                    required
                                    label='Confirm Password'
                                />
                            </Grid>
                        )}
                        <Grid item xs={12}>
                            {hostsLoaded ? (
                                <TextField
                                    {...hostId.formProps}
                                    required
                                    select
                                    label='Host'
                                >
                                    {hosts.map(host => (
                                        <MenuItem key={host.host_id} value={host.host_id}>
                                            {host.host_name}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            ) : (
                                <TextField
                                    label='Host'
                                    disabled
                                />
                            )}
                        </Grid>
                    </Grid>

                    <Grid item xs={12}>
                        <Box display='flex' flexDirection='column' mt={2} alignItems='flex-start'>
                            <FormControlLabel control={<Switch {...status.formProps} />} label="User Enabled" />
                            <SwitchSubHeader>Turn this off to block the user's access to the portal</SwitchSubHeader>
                        </Box>
                    </Grid>


                    <Box display='flex' justifyContent='flex-end' mt={4}>
                        <CancelButton variant='outlined' onClick={onCancel} disabled={loading}>Cancel</CancelButton>
                        <SaveButton variant='outlined' onClick={handleSubmit} disabled={loading}>Save</SaveButton>
                    </Box>

                </FormContainer>

                {editMode && ('clipboard' in navigator) && (
                    <UserIdContainer>
                        <CardContent>
                            <Typography>User ID:</Typography>
                            <Typography variant='h6'>{userId}</Typography>
                        </CardContent>
                        <CardActions>
                            <LinkButton startIcon={<FileCopyIcon />} onClick={copyIdToClipboard}>Copy</LinkButton>
                        </CardActions>
                    </UserIdContainer>
                )}

            </Box>
        </Box>
    )
}

const FormContainer = styled(Paper)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2)
}));

const UserIdContainer = styled(Card)(({ theme }) => ({
    marginTop: theme.spacing(2)
}));

const SaveButton = styled(Button)(({ theme }) => ({
    color: theme.palette.success.main,
    borderColor: theme.palette.success.main
}));

const CancelButton = styled(Button)({
    marginRight: '1rem'
});

const LinkButton = styled(Button)(({ theme }) => ({}));
LinkButton.defaultProps = { variant: 'outlined', color: 'secondary' };

const SwitchSubHeader = styled(Typography)(({ theme }) => ({
    marginLeft: 48,
    marginTop: -6,
    marginBottom: 8
}));
SwitchSubHeader.defaultProps = { variant: 'caption' };
import React from 'react';
import { styled, Box, Typography, Card, CardHeader, CardContent } from '@material-ui/core';
import moment from 'moment-timezone';


export default function RegistrationReview(props) {
    const { attendees } = props;

    return (
        <Card>
            <CardHeader title='Checkout Form Responses' />
            <CardContent>
                {attendees.map((attendee, index) => (
                    <Attendee
                        attendee={attendee}
                        attendeeNumber={index+1}
                    />
                ))}
            </CardContent>
        </Card>
    )
}


function Attendee(props) {
    const { attendee, attendeeNumber } = props;
    
    return (
        <Box mb={4}>
            {attendee.productName && (
                <AttendeeName>Attendee #{attendeeNumber}: {attendee.productName}</AttendeeName>
            )}
            {attendee.form.map((r,i) => (
                <Box key={i} mt={2}>
                    <Typography variant='caption' gutterBottom>{r.desc}</Typography>
                    <Answer
                        type={r.type}
                        answer={r.value}
                        options={r.options}
                    />
                </Box>
            ))}
        </Box>
    )
}


function Answer(props) {
    const { type, answer, options } = props;

    let display = '-';

    if (answer !== null && answer !== undefined && answer !== '') {
        switch (type) {
            case 1:
            case 2:
                display = answer;
                break;
            case 3:
            case 5:
                for (const o of options) {
                    if (answer === o.id) {
                        display = o.desc;
                    }
                }
                break;
            case 4:
                let answerList = [];
                for (const a of answer.entries()) {
                    for (const o of options) {
                        if (a[0] === o.id) {
                            answerList.push(o.desc);
                        }
                    }
                }
                if (answerList.length > 0) {
                    display = answerList.join(' | ');
                }
                break;
            case 6:
                display = moment(answer).format('YYYY-MM-DD');
                break;
            case 7:
                display = answer;
                break;
            case 8:
                display = answer ? 'Yes' : 'No';
                break;
            case 9:
                display = moment(answer).format('YYYY-MM');
                break;
            case 10:
                display = answer ? 'Accepted' : 'Rejected';
                break;
            default:
                display = answer;
                break;
        }
    }


    return <Typography>{display}</Typography>
}


const AttendeeName = styled(Typography)(({ theme }) => ({
    fontWeight: 'bold'
}));
import React, { useState } from 'react';
import { styled, Box, Button, Typography, Grid } from '@material-ui/core';
import { TableContainer, Table, TableBody, TableRow, TableCell } from '@material-ui/core';
import { Card, CardHeader, CardContent as _CardContent } from '@material-ui/core';
import RegistrationReview from 'components/registration/RegistrationReview';
import TicketEmailer from 'components/orders/TicketEmailer';
import TicketDownloader from 'components/orders/TicketDownloader';
import { Alert } from '@material-ui/lab';
import { ScrollRestoreContainer } from 'components/ui/layout';



export default function PostOrderSummary(props) {
    const { orderId, customer, products, bundles, registration, onClose, onRestart } = props;

    const [alertOpen, setAlertOpen] = useState(true);

    const productCart = products.filter(p => p.quantity > 0);
    const bundleCart = bundles.filter(b => b.quantity > 0);

    const multiTicket = bundleCart.length > 0 || productCart.reduce((acc, p) => acc + p.quantity, 0) > 1;


    return (
        <ScrollRestoreContainer>

            <FormContainer>

                {alertOpen && (
                    <Box mb={4}>
                        <Alert severity='success' variant='filled' action={<Button color='inherit' size='small' onClick={() => setAlertOpen(false)}>Dismiss</Button>}>
                            Order has been created successfully. Sending the ticket email or downloading the tickets can be done now and is optional.
                        </Alert>
                    </Box>
                )}

                <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                        <TicketEmailer
                            multiTicket={multiTicket}
                            email={customer.email}
                            tranId={orderId}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TicketDownloader
                            multiTicket={multiTicket}
                            lastName={customer.lastName}
                            tranId={orderId}
                            email={customer.email}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Card>
                            <CardHeader title='Summary' />

                            <CardContent>

                                <TableContainer component={Box}>
                                    <Table size='small'>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell>Order #</TableCell>
                                                <TableCell align='right' style={{ fontSize: 22 }}>F{orderId}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <HeaderCell><HeaderText>Customer</HeaderText></HeaderCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>Name</TableCell>
                                                <TableCell align='right'>{customer.firstName.trim()} {customer.lastName.trim()}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>Email</TableCell>
                                                <TableCell align='right'>{customer.email.trim() || '-'}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>Phone</TableCell>
                                                <TableCell align='right'>{customer.phone.trim() || '-'}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>Company Name</TableCell>
                                                <TableCell align='right'>{customer.company.trim() || '-'}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>Address</TableCell>
                                                <TableCell align='right'>{customer.address.trim() || '-'}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>Location</TableCell>
                                                <TableCell align='right'>{customer.city.trim()} {customer.province.trim()} {customer.postalCode.trim()} {customer.country === 'CA' ? 'Canada' : 'United States'}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <HeaderCell><HeaderText>Tickets</HeaderText></HeaderCell>
                                            </TableRow>
                                            {bundleCart.map(t => (
                                                <TableRow key={t.id}>
                                                    <TableCell>{t.name}</TableCell>
                                                    <TableCell align='right'>{t.quantity}</TableCell>
                                                </TableRow>
                                            ))}
                                            {productCart.map(t => (
                                                <TableRow key={t.id}>
                                                    <TableCell>{t.name}</TableCell>
                                                    <TableCell align='right'>{t.quantity}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>

                            </CardContent>

                        </Card>
                    </Grid>
                    {registration.length > 0 && (
                        <Grid item xs={12}>
                            <RegistrationReview attendees={registration} />
                        </Grid>
                    )}
                </Grid>


                <ButtonContainer>
                    <RestartButton variant='outlined' onClick={onRestart}>Create New Order</RestartButton>
                    <FinishButton variant='contained' onClick={onClose}>Back to Order List</FinishButton>
                </ButtonContainer>

            </FormContainer>

        </ScrollRestoreContainer>
    )
}



const FormContainer = styled(Box)(({ theme }) => ({
    width: theme.breakpoints.values.md,
    paddingBottom: theme.spacing(10)
}));

const CardContent = styled(_CardContent)(({ theme }) => ({
    minHeight: 60
}));

const HeaderCell = styled(TableCell)(({ theme }) => ({
    border: 'none',
}));

const HeaderText = styled(Typography)(({ theme }) => ({
    marginTop: theme.spacing(2),
    fontWeight: 'bold'
}));

const ButtonContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
}));

const RestartButton = styled(Button)(({ theme }) => ({
    color: theme.palette.secondary.main,
    borderColor: theme.palette.secondary.main,
    marginLeft: theme.spacing(2),
}));
RestartButton.defaultProps = { variant: 'outlined' }

const FinishButton = styled(Button)(({ theme }) => ({
    backgroundColor: theme.palette.success.main,
    color: theme.palette.common.white,
    marginLeft: theme.spacing(2),
}));
FinishButton.defaultProps = { variant: 'contained' }
import React, { useEffect, useReducer, useRef } from 'react';
import { styled, Box } from '@material-ui/core';

import FullScreenModal from 'components/popups/FullScreenModal';
import OrderCreateForm from 'components/orders/OrderCreateForm';
import Registration from 'components/registration/Registration';
import { isRegistrationRequired, initializeAttendee, formatRegistrationFormForPurchase } from 'components/registration/RegistrationUtils';
import OrderReview from 'components/orders/OrderReview';
import PostOrderSummary from 'components/orders/PostOrderSummary';
import fetchExpresso from 'utility/fetchExpresso';

import { useEvent } from 'context/event';
import { useNotification } from 'context/notification';
import moment from 'moment-timezone';
import { ErrorPage } from 'components/ui/states';



const defaultState = {
    page: 1,
    cart: {
        products: [],
        bundles: [],
        status: 'loading', // loading, error, success
    },
    customer: {
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        company: '',
        address: '',
        city: '',
        province: '',
        postalCode: '',
        country: 'CA'
    },
    order: {
        loading: false,
        orderId: null
    },
    registration: {
        mode: 0,
        questions: [],
        form: []
    }
}


function reducer(state, action) {
    switch (action.type) {
        case 'setCart':
            return { ...state, cart: { products: action.products, bundles: action.bundles, status: 'success' }, registration: action.registration };

        case 'setCartError':
            return { ...state, cart: { products: [], bundles: [], status: 'error' } }

        case 'updateCartProducts':
            const products = state.cart.products.map(i => {
                if (action.id === i.id) {
                    return { ...i, quantity: action.value };
                } else {
                    return i;
                }
            })

            const p_form = isRegistrationRequired(state.registration.mode, state.registration.questions, products, state.cart.bundles)
                ? initializeAttendee(state.registration.mode, products, state.cart.bundles, state.registration.questions)
                : [];

            return {
                ...state,
                cart: { ...state.cart, products: products },
                registration: { ...state.registration, form: p_form }
            }

        case 'updateCartBundles':
            const bundles = state.cart.bundles.map(i => {
                if (action.id === i.id) {
                    return { ...i, quantity: action.value };
                } else {
                    return i;
                }
            })

            const b_form = isRegistrationRequired(state.registration.mode, state.registration.questions, state.cart.products, bundles)
                ? initializeAttendee(state.registration.mode, state.cart.products, bundles, state.registration.questions)
                : [];

            return {
                ...state,
                cart: { ...state.cart, bundles: bundles },
                registration: { ...state.registration, form: b_form }
            }

        case 'updateCustomer':
            return { ...state, customer: { ...state.customer, ...action.form } };

        case 'updateRegistrationForm':
            return { ...state, registration: { ...state.registration, form: action.form } };

        case 'setOrderLoading':
            return { ...state, order: { ...state.order, loading: action.value }};

        case 'setOrder':
            return { ...state, page: 4, order: { ...state.order, loading: false, orderId: action.id } };

        case 'next':
            if (state.page + 1 === 2 && state.registration.form.length === 0) {
                return { ...state, page: state.page + 2 }; // Skip Registration if no questions are avilable to answer
            } else {
                return { ...state, page: state.page + 1 };
            }

        case 'back':
            if (state.page - 1 === 2 && state.registration.form.length === 0) {
                return { ...state, page: state.page - 2 }; // Skip Registration if no questions are avilable to answer
            } else {
                return { ...state, page: state.page - 1 };
            }
        
        case 'restart':
            return {
                ...defaultState,
                cart: {
                    products: state.cart.products.map(i => ({ ...i, quantity: 0 })),
                    bundles: state.cart.bundles.map(i => ({ ...i, quantity: 0 })),
                    status: 'success' 
                },
                registration: {
                    ...state.registration,
                    form: []
                }
            };

        case 'defaultState':
            return defaultState;

        default:
            throw new Error();
    }
}



export default function OrderCreateModal(props) {
    const { open, onClose, eventId, onOrderCreate } = props;

    const { notify } = useNotification();
    const event = useEvent();
    const timezone = event.time_zone;
    
    const [state, dispatch] = useReducer(reducer, defaultState);

    let modalTitle;
    if (state.page === 1) modalTitle = 'Create Order';
    if (state.page === 2) modalTitle = 'Checkout Questions'; // Skipped if there are no registration questions to ask
    if (state.page === 3) modalTitle = 'Review Order';
    if (state.page === 4) modalTitle = 'Share Order';


    const eventIdRef = useRef();

    useEffect(() => {
        // Event ID has changed. Reset state for this component.
        if (eventId !== eventIdRef.current) {
            dispatch({ type: 'defaultState' });
            eventIdRef.current = eventId;
        }
        
        // Load or reload products whenever the modal is opened (or event id changes)
        if (open === true) {

            Promise.all([
                fetchExpresso(`/apiv2/events/${eventId}/comp-products`),
                fetchExpresso(`/apiv2/events/${eventId}/registration/registration-form`),
            ])
                .then(async result => {
                    if (result[0].status !== 200 || result[1].status !== 200) {
                        throw new Error();
                    }

                    const [_products, _registration] = await Promise.all([
                        result[0].json(),
                        result[1].json(),
                    ])

                    const products = _products.products.map(p => {
                        let status = 'on_sale';
        
                        if (moment(p.sale_start_date).tz(timezone, true) > moment()) status = 'sale_not_started';
                        if (moment(p.sale_end_date).tz(timezone, true) < moment()) status = 'sale_concluded';
                        if (p.qty_left <= 0) status = 'sold_out';
                        if (p.visible === 0) status = 'hidden';

                        return {
                            id: p.product_id,
                            name: p.prod_name,
                            type: p.prod_type_id,
                            status: status,
                            quantity: 0,
                            isAttendee: Boolean(p.is_attendee)
                        }
                    });

                    const bundles = _products.bundles.map(b => {
                        let status = 'on_sale';
        
                        if (moment(b.sale_start_date).tz(timezone, true) > moment()) status = 'sale_not_started';
                        if (moment(b.sale_end_date).tz(timezone, true) < moment()) status = 'sale_concluded';
                        if (b.qty_left <= 0) status = 'sold_out';
                        if (b.visible === 0) status = 'hidden';

                        return {
                            id: b.bundle_id,
                            name: b.bundle_name,
                            status: status,
                            quantity: 0,
                            products: b.products.map(bp => ({
                                id: bp.product_id,
                                name: bp.prod_name,
                                quantity: bp.qty,
                                price: bp.price,
                                isAttendee: Boolean(bp.is_attendee)
                            }))
                        }
                    })

                    const registration = {
                        mode: _registration.checkout_question_mode,
                        questions: _registration.questions.map(q => ({ ...q, required: 0 })), // No questions are required when filled out by the host
                        form: []
                    }

                    dispatch({ type: 'setCart', products, bundles, registration });
                })
                .catch(() => dispatch({ type: 'setCartError' }))
        }
    }, [open, eventId, timezone]);



    const handleClose = () => {
        onClose();
        setTimeout(() => {
            dispatch({ type: 'defaultState' });
        }, 500);
    };


    const handleSubmit = () => {
        const { firstName, lastName, email, phone, company, address, city, province, postalCode, country } = state.customer;
        const { form } = state.registration;

        dispatch({ type: 'setOrderLoading', value: true });

        fetchExpresso('/apiv2/orders/', {
            method: 'POST',
            body: {
                eventId: eventId,
                customer: {
                    firstName: firstName.trim(),
                    lastName: lastName.trim(),
                    email: email.trim(),
                    phone: phone.trim() || null,
                    company: company.trim() || null,
                    address: address.trim() || null,
                    city: city.trim() || null,
                    province: province || null,
                    postalCode: postalCode.trim() || null,
                    country: country
                },
                products: state.cart.products.filter(i => i.quantity > 0),
                bundles: state.cart.bundles.filter(i => i.quantity > 0),
                attendees: formatRegistrationFormForPurchase(form),
            }
        })
            .then(async res => {
                if (res.status !== 200) {
                    throw new Error();
                }

                const order = await res.json();

                dispatch({ type: 'setOrder', id: order.tran_id });

                onOrderCreate(); // Notifies <Order /> component that an order was created. Order list will refresh.
            })
            .catch(() => {
                notify.error('There was a problem creating your order');
                dispatch({ type: 'setOrderLoading', value: false });
            })
    };


    // No tickets are available. Orders cannot be created
    const ticketsNotAvailable = (state.cart.status === 'success' && state.cart.products.length === 0 && state.cart.bundles.length === 0);


    return (
        <FullScreenModal open={open} onClose={handleClose} title={modalTitle} cancelText='Cancel'>

            {ticketsNotAvailable ? (
                <ErrorPage message='No tickets found. Please ensure you have created at least one ticket before making an order.' />
            ) : (
                <Root p={[2,4]}>
                    {open === true && state.page === 1 && (
                        <OrderCreateForm
                            customer={state.customer}
                            cart={state.cart}
                            onCartProductUpdate={(id, value) => dispatch({ type: 'updateCartProducts', id, value })}
                            onCartBundleUpdate={(id, value) => dispatch({ type: 'updateCartBundles', id, value })}
                            onCustomerUpdate={(form) => dispatch({ type: 'updateCustomer', form })}
                            onSubmit={() => dispatch({ type: 'next' })}
                            onCancel={handleClose}
                        />
                    )}

                    {open === true && state.page === 2 && (
                        <Registration
                            form={state.registration.form}
                            mode={state.registration.mode}
                            onFormChange={form => dispatch({ type: 'updateRegistrationForm', form })}
                            onSubmit={() => dispatch({ type: 'next' })}
                            onCancel={() => dispatch({ type: 'back' })}
                        />
                    )}

                    {open === true && state.page === 3 && (
                        <OrderReview
                            customer={state.customer}
                            cart={state.cart}
                            registration={state.registration.form}
                            loading={state.order.loading}
                            onCancel={() => dispatch({ type: 'back' })}
                            onSubmit={handleSubmit}
                        />
                    )}

                    {open === true && state.page === 4 && (
                        <PostOrderSummary
                            orderId={state.order.orderId}
                            customer={state.customer}
                            products={state.cart.products}
                            bundles={state.cart.bundles}
                            registration={state.registration.form}
                            onClose={handleClose}
                            onRestart={() => dispatch({ type: 'restart' })}
                        />
                    )}
                </Root>
            )}

        </FullScreenModal>
    )
}


const Root = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.grey[100],
    display: 'flex',
    justifyContent: 'center',
    flexGrow: 1,
}));